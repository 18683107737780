<template>
    <v-container fluid>
        <v-row class="mb-10">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                     <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col>
                <v-card>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2"
                                    hide-details=true 
                                    v-model="entiti" 
                                    :items="entitis" 
                                    default="" 
                                    item-value="Entity" 
                                    item-text="entity" 
                                    label="Entity ID"
                                    prepend-icon="mdi-garage" 
                                    @change="(event) => getRegion(event)"    
                                    :disabled="disabled_entity"
                                    >
                                </v-autocomplete>
                                
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2"
                                    hide-details=true 
                                    v-model="region" 
                                    :items="regionss" 
                                    default="" 
                                    item-value="region" 
                                    item-text="region" 
                                    label="Region" 
                                    @change="(event) => getOffice(event)"    
                                    clearable
                                    prepend-icon="mdi-store" 
                                    :disabled="disabled_region"
                                    >
                                </v-autocomplete>
                                
                                </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2"
                                    hide-details=true 
                                    v-model="office" 
                                    :items="offices" 
                                    default="" 
                                    item-value="office_id" 
                                    :item-text="item => item.office_id +' - '+ item.office"
                                    label="Office ID" 
                                    clearable 
                                    :disabled="disabled_office"
                                    prepend-icon="mdi-home-assistant"
                                    >
                                </v-autocomplete>
                                </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal" 
                                        v-model="modal" 
                                        :close-on-content-click="false" 
                                        transition="scale-transition" 
                                        offset-y 
                                        max-width="290px" 
                                        min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo v-model="date_from" 
                                        label="Periode" 
                                        persistent-hint prepend-inner-icon="mdi-calendar" 
                                        v-bind="attrs" v-on="on" 
                                        class="ma-0 pa-2" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="date_from" 
                                        type="month" 
                                        no-title @input="modal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>    
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-3" 
                                color="info" elevation="2" large @click="submit()">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-row class="mt-2" style="padding-bottom:50px">
                <v-col cols="12">
                    <v-col cols="12" xs="12" sm="12" md="2">
                        <v-btn block color="#053d76" class="text-white rounded-l mr-4" height="50px">
                            <v-icon>mdi-plus</v-icon>
                            <download-excel
                            class="text"
                            :fetch           = "exportExcel"
                            :fields="headersColumn"
                            :before-generate = "startDownload"
                            :before-finish   = "finishDownload">
                            Export Detail Excel
                        </download-excel> 
                        </v-btn>
                    </v-col>
                    <v-card class="rounded-l elevation-5" color="blue lighten-5">
                        <v-row align="center" justify="center" style="margin-top: 2px;">
                                <v-col cols="4" xs="12" sm="6" md="2"  style="background: #0078d4 ;border-bottom-left-radius: 30px;border-bottom-right-radius: 30px;">
                                    <h4 class="text-white text-center" style="font-weight:700">
                                        Summary
                                    </h4>
                                    <h5 class="text-white text-center">Report</h5>
                                </v-col>
                            </v-row>
                    <v-card-text>
                        <v-data-table
                            dense
                            fixed-header="true"
                            :headers="headers"
                            :items="menu_headers"
                            :loading="$store.state.overlay"
                            hide-default-footer
                        >                
                         </v-data-table>
                       </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <!-- garis tengah bukan garis biru -->
             <v-row style="padding-bottom:20px">
                <v-col cols="12">
                    <v-card class="rounded-l elevation-5"  color="indigo lighten-4">
                        <v-row align="center" justify="center" style="margin-top: -30px;">
                                <v-col cols="4" xs="12" sm="6" md="2"  style="background: #3F51B5 ;border-bottom-left-radius: 30px;border-bottom-right-radius: 30px;">
                                    <h4 class="text-white text-center" style="font-weight:700">
                                        Summary
                                    </h4>
                                    <h5 class="text-white text-center">Brand ID</h5>
                                </v-col>
                            </v-row>
                    <v-card-text>
                        <v-data-table
                             dense
                            :headers="headersss"
                            fixed-header="true"
                            height="300"
                            :items="menusums"
                            class="elevation-1"
                            :items-per-page="-1"
                            hide-default-footer
                            :loading="$store.state.overlay"
                            
                        >                
                         </v-data-table>
                       </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
             <v-row style="padding-bottom:20px">
                <v-col cols="12">
                    <v-card class="rounded-l elevation-5"  color="teal lighten-5">
                        <v-row align="center" justify="center" style="margin-top: 2px;">
                                <v-col cols="4" xs="12" sm="6" md="2"   style="background: #009688 ;border-bottom-left-radius: 30px;border-bottom-right-radius: 30px;">
                                    <h4 class="text-white text-center" style="font-weight:700">
                                        Summary Region Per-Brand
                                    </h4>
                                    <h5 class="text-white text-center">Report</h5>
                                </v-col>
                            </v-row>
                    <v-card-text>
                        <v-data-table
                            dense
                            :headers="headerssss"
                            fixed-header="true"
                            height="300"
                            :items="menu_headersss"
                            class="elevation-1"
                            :items-per-page="-1"
                            hide-default-footer
                            :loading="$store.state.overlay"
                        >                
                         </v-data-table>
                       </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row style="padding-bottom:20px">
                <v-col cols="12">
                    <v-card class="rounded-l elevation-5"  color="red lighten-5">
                        <v-row align="center" justify="center" style="margin-top: 2px;">
                                <v-col cols="4" xs="12" sm="6" md="2"   style="background: #FF5722 ;border-bottom-left-radius: 30px;border-bottom-right-radius: 30px;">
                                    <h4 class="text-white text-center" style="font-weight:700">
                                        Detail
                                    </h4>
                                    <h5 class="text-white text-center">Report</h5>
                                </v-col>
                            </v-row>
                    <v-card-text>
                        <v-data-table
                            class="elevation-1"    
                            dense
                            fixed-header
                            height="300"
                            :headers="headerss"
                            :items="menu_headerss"
                            :items-per-page="-1"
                            hide-default-footer
                            :loading="$store.state.overlay"
                        >                
                         </v-data-table>
                       </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-row>
    </v-container>
</template>
<script>

import {filter} from "../../../backend-api/filter/index"
import {backendApi} from "../../../backend-api/backend-api-sr"
import {forecast} from "../../../backend-api/viva/forecast"

export default {
        data() {
            return {
                loading: false,
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
            },
            timeout: 7500,
            search:'',
            breadcumbs: [
                {
                    text: 'Viva',
                    disabled: false,
                    href: '/admin/viva',
                },
                {
                    text: 'Forecast',
                    disabled: false,
                    href: '/admin/viva/dashboard',
                },
                {
                    text: 'Forecast vs Realisasi',
                    disabled: true,
                }
            ],
            headers:[
                {
                text: 'Forecast',
                align: 'start',
                value: 'forecast', 
                align:'center'},
                { text: 'Realisasi', value: 'realisasi' , align:'center'},
            ],
            headersss:[
                {
                text: 'Brand ID',
                align: 'start',
                value: 'brand_id', 
                align:'center'},
                { text: 'Forecast', value: 'forecast' , align:'center'},
                { text: 'Realisasi', value: 'realisasi' , align:'center'},
            ],
            menu_headers:[],
            headerss:[
                {
                text: 'Region',
                align: 'start',
                value: 'region', 
                align:'center'},
                // { text: 'Region', value: 'region' , align:'center'},
                { text: 'Office ID', value: 'office_id' , align:'center'},
                { text: 'Brand ID', value: 'brand_id' , align:'center'},
                { text: 'Width', value: 'width' , align:'center'},
                { text: 'Thick', value: 'thick' , align:'center'},
                { text: 'Grade', value: 'grade' , align:'center'},
                { text: 'Az', value: 'az' , align:'center'},
                { text: 'forecast', value: 'forecast' , align:'center'},
                { text: 'Realisasi', value: 'realisasi' , align:'center'},
                //{ text: 'Action', value: 'action' , align:'center'}, 
            ],
            headerssss:[
                {
                text: 'Region',
                align: 'start',
                value: 'region', 
                align:'center'},
                // { text: 'Region', value: 'region' , align:'center'},
               // { text: 'Office ID', value: 'office_id' , align:'center'},
                { text: 'Brand ID', value: 'brand_id' , align:'center'},
                { text: 'Width', value: 'width' , align:'center'},
                { text: 'Thick', value: 'thick' , align:'center'},
                { text: 'Grade', value: 'grade' , align:'center'},
                { text: 'Az', value: 'az' , align:'center'},
                { text: 'forecast', value: 'forecast' , align:'center'},
                { text: 'Realisasi', value: 'realisasi' , align:'center'},
                //{ text: 'Action', value: 'action' , align:'center'}, 
            ],
            headersColumn: {
                'Entity ID':'entity_id',
                'Region': 'region',
                'Office ID': 'office_id',
                'Tahun': 'tahun',
                'Bulan': 'bulan',
                'Cat Desc': 'cat_desc',
                'Brand ID': 'brand_id',
                'Width': 'width',
                'Thick': 'thick',
                'Grade': 'grade',
                'Az': 'az',
                'Forecast': 'forecast',
                'Realisasi': 'realisasi',
                
            },
            menu_headerss:[],
            menu_headersss:[],
            menusums:[],
            datas:[],
            offices:[],
            entitis:[],
            regions:[],
            regionss:[],
            region: '',
            modal: false,
            modal_to: false, 
            date_from:'',
            date_to:'',
            status: '',
            entiti:'',
            customer:'',
            office:'',
            envUserData:[],
            disabled_entity: false,
            disabled_region: false,
            disabled_office: false,
            disabledExport:false,
            group_id: ''
            }
        },
    async mounted(){
        // this.disabled_entity = true
        // this.disabled_region = true
        // this.disabled_office = true
        // await this.getEntity()
        // await this.getRegion()
        // await this.getOffice()
        // await this.getEnvConf()
        await this.getEnvConfTwo()
    },
    methods: {
        async getEnvConf(event){
            let varEntity = false
            let varRegion = false
            let varOffice = false
            const respData = await backendApi.fetchCore(`/api/get_group_user?entity_id=VIVA&appl_id=WEBVIVA`, null, false, false, false)
            if (respData.status === 200) {
                this.group_id = respData.data.data
                // console.log(this.group_id)
                let data = {
                    entity_id : 'VIVA',
                    appl_id : 'WEBVIVA',
                    var_id : ['WHOAMI', 'BRANCH', 'REGION'],
                    group_id : this.group_id
                }
                
                const respDataGroup = await forecast.fetchEnvConfGroup(data);
                if (respDataGroup.data.data.length > 0) {
                    for (let i = 0; i < respDataGroup.data.data.length; i++) {
                        console.log(respDataGroup.data.data[i]['var_id'])
                        if (respDataGroup.data.data[i]['var_id'] === 'WHOAMI') {
                            this.entiti = respDataGroup.data.data[i]['var_value']
                            if(this.entiti.includes(",")){
                                // console.log(this.entiti)
                                this.entitis = this.entiti.split(",");
                                this.entiti = this.entitis[0]
                            } else {
                                this.disabled_entity = true
                                varEntity = true
                            }
                        }
                        if (respDataGroup.data.data[i]['var_id'] === 'REGION') {
                            this.region = respDataGroup.data.data[i]['var_value']
                            this.disabled_region = true
                            varRegion = true
                        }
                        if (respDataGroup.data.data[i]['var_id'] === 'BRANCH') {
                            this.office = respDataGroup.data.data[i]['var_value']
                            this.disabled_office = true
                            varOffice = true
                        }
                    }
                }

                let dataUser = {
                    entity_id : 'VIVA',
                    appl_id : 'WEBVIVA',
                    var_id : ['WHOAMI', 'BRANCH', 'REGION'],
                }
                this.envUserData = await filter.fetchEnvConfUser(dataUser);
                if (this.envUserData.data.data.length > 0) {
                    for (let i = 0; i < this.envUserData.data.data.length; i++) {
                        if (this.envUserData.data.data[i]['var_id'] === 'WHOAMI') {
                            this.entiti = this.envUserData.data.data[i]['var_value']
                            if(this.entiti.includes(",")){
                                // console.log(this.entiti)
                                this.entitis = this.entiti.split(",");
                                this.entiti = this.entitis[0]
                                // console.log(res)
                            } else {
                                this.disabled_entity = true
                                varEntity = true
                            }
                        }
                        if (this.envUserData.data.data[i]['var_id'] === 'REGION') {
                            this.region = this.envUserData.data.data[i]['var_value']
                            this.disabled_region = true
                            varRegion = true
                        }
                        if (this.envUserData.data.data[i]['var_id'] === 'BRANCH') {
                            this.office = this.envUserData.data.data[i]['var_value']
                            this.disabled_office = true
                            varOffice = true
                        }
                    }
                }
            }
            if (!varEntity){
                this.disabled_entity = false
            }
            if (!varRegion){
                this.disabled_region = false
            }
            if (!varOffice){
                this.disabled_office = false
            }
        },
        async getEnvConfTwo(){
            let varEntity = false
            let varRegion = false
            let varOffice = false
            let ent = []
            let reg = ''
            let off = ''
            const respData = await backendApi.fetchCore(`/api/get_group_user?entity_id=VIVA&appl_id=WEBVIVA`, null, false, false, false)
            if (respData.status === 200) {
                this.group_id = respData.data.data
                let data = {
                    entity_id : 'VIVA',
                    appl_id : 'WEBVIVA',
                    var_id : ['WHOAMI', 'BRANCH', 'REGION'],
                    group_id : this.group_id
                }
                let dataUser = {
                    entity_id : 'VIVA',
                    appl_id : 'WEBVIVA',
                    var_id : ['WHOAMI', 'BRANCH', 'REGION'],
                }
                const respDataGroup = await forecast.fetchEnvConfGroup(data);
                const respUserData = await filter.fetchEnvConfUser(dataUser);
                // console.log(respDataGroup)
                // console.log(respUserData)
                if (respDataGroup.data.data.length > 0) {
                    for (let i = 0; i < respDataGroup.data.data.length; i++) {
                        if (respDataGroup.data.data[i]['var_id'] === 'WHOAMI') {
                            let d = respDataGroup.data.data[i]['var_value']
                            if(d.includes(",")){
                                ent = d.split(",")
                            } else {
                                ent = [d]
                            }
                        }
                        if (respDataGroup.data.data[i]['var_id'] === 'REGION') {
                            reg = respDataGroup.data.data[i]['var_value']
                        }
                        if (respDataGroup.data.data[i]['var_id'] === 'BRANCH') {
                            off = respDataGroup.data.data[i]['var_value']
                        }
                    }
                }
                if (respUserData.data.data.length > 0) {
                    for (let i = 0; i < respUserData.data.data.length; i++) {
                        if (respUserData.data.data[i]['var_id'] === 'WHOAMI') {
                            let d = respUserData.data.data[i]['var_value']
                            if(d.includes(",")){
                                ent = d.split(",")
                            } else {
                                ent = [d]
                            }
                        }
                        if (respUserData.data.data[i]['var_id'] === 'REGION') {
                            reg = respUserData.data.data[i]['var_value']
                        }
                        if (respUserData.data.data[i]['var_id'] === 'BRANCH') {
                            off = respUserData.data.data[i]['var_value']
                        }
                    }
                }
                console.log(ent)
                let bodyData = {
                    "entity_id_in": ent,
                    "region": reg,
                    "office": off
                }
                await this.getAllFilter(bodyData)
            }
        },
        async getAllFilter(bodyData){
            var respData = await forecast.getFilterTwo(bodyData, null, false, false, false)
            if (respData.status === 200) {
                this.entitis = respData.data.data.entity
                this.entiti = this.entitis[0].entity
                if(this.entitis.length === 1){
                    this.disabled_entity = true
                }
                this.regionss = respData.data.data.region
                if(this.regionss.length === 1){
                    this.disabled_region = true
                }
                // this.region = this.regionss[0].region
                this.offices = respData.data.data.office
                if(this.offices.length === 1){
                    this.disabled_office = true
                }
                // this.office = this.offices[0].office_id
                // console.log(this.entiti)
                // console.log("this.entiti")
                // console.log(this.entitis[0])
                // console.log(this.region)
                // console.log(this.regionss[0])
                // console.log(this.office)
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Connection problem, please refresh. If problem persist contact IT.',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                return false
            }
        },
        startDownload(){
           this.loading = true
           this.disabledExport=true
        },
        finishDownload(){
            this.loading = false
            this.disabledExport=false
        },

        async exportExcel(event){
             if (this.entiti == '' || this.entiti === null || this.date_from == '' || this.date_from === null) {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please Insert Complate Entity ID and Period !',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                this.dialog = true
                return false
                }
            if (event === null || event === '') {
                this.entiti = ''
            } else{
                this.$store.dispatch('setOverlay', true)
                var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 8).replace(/-/g,"") : ''
                var respData = await filter.fetchforecast (`?entity_id=${this.entiti ? this.entiti : ''}&office_id=${this.office ? this.office : ""}&region=${this.region ? this.region : ""}&period_start=${start_date ? start_date : ""}`, null, false, false, false)
                if (respData.status === 200) {
                    this.$store.dispatch('setOverlay', false)
                    return respData.data.data
                }
            }
        },

        async getGroup(event){
            if (event === null || event === '') {
                this.region = ''
                this.office = ''
            } else{
                this.$store.dispatch('setOverlay', true)
                var respData = await filter.fetchforecastfilter(`?entity_id=${this.entiti ? this.entiti : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    console.log(respData)
                    // this.$store.dispatch('setOverlay', false)
                    // this.regionss = respData.data.data.Region
                    // this.getOffice(event)
                }
            }
        },


        async getEntity(event){
            if (event === null || event === '') {
                this.entitis = ''
            } else{
                this.$store.dispatch('setOverlay', true)

                var respData = await filter.fetchforecastfilter(`?entity_id=${this.entiti ? this.entiti : ''}`, null, false, false, false)
                if (respData.status === 200) {
                   // console.log(respData)
                    this.entitis = respData.data.data.Entity
                    this.$store.dispatch('setOverlay', false)


                }
            }
        },

         async getSumaja(event){
            if (event === null || event === '') {
                this.menusums = ''
            } else{
                this.$store.dispatch('setOverlay', true)
                var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 8).replace(/-/g,"") : ''
                var respData = await filter.fetchsum (`?entity_id=${this.entiti ? this.entiti : ''}&office_id=${this.office ? this.office : ""}&region=${this.region ? this.region : ""}&period_start=${start_date ? start_date : ""}`, null, false, false, false)
                if (respData.status === 200) {
                    console.log(respData)
                    this.menusums = respData.data.data
                    this.$store.dispatch('setOverlay', false)


                }
            }
        },

         async getSumbrand(event){
            if (event === null || event === '') {
                this.menu_headersss = ''
            } else{
                this.$store.dispatch('setOverlay', true)
                var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 8).replace(/-/g,"") : ''
                var respData = await filter.fetchforecastbrand (`?entity_id=${this.entiti ? this.entiti : ''}&office_id=${this.office ? this.office : ""}&region=${this.region ? this.region : ""}&period_start=${start_date ? start_date : ""}`, null, false, false, false)
                if (respData.status === 200) {
                    // /console.log(respData)
                    this.menu_headersss = respData.data.data
                    this.$store.dispatch('setOverlay', false)


                }
            }
        },

        async getOffice(event){
               console.log(this.entiti)
         
            if (event === null || event === '') {
                this.office = ''
            } else{
                this.$store.dispatch('setOverlay', true)

                var respData = await filter.fetchforecastfilter(`?entity_id=${this.entiti ? this.entiti : ''}&region=${this.region ? this.region : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    this.offices = respData.data.data.Office
                    this.$store.dispatch('setOverlay', false)
                }
            }
        },
   
        async getRegion(event){
            console.log(this.entiti)
            if (event === null || event === '') {
                this.region = ''
                this.office = ''
            } else{
                this.$store.dispatch('setOverlay', true)
                var respData = await filter.fetchforecastfilter(`?entity_id=${this.entiti ? this.entiti : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    this.$store.dispatch('setOverlay', false)
                    this.regionss = respData.data.data.Region
                    this.getOffice(event)
                }
            }
        },

        async submit(){
            //console.log(this.date_from)
            if (this.entiti == '' || this.entiti === null || this.date_from == '' || this.date_from === null) {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please Insert Complate Entity ID and Period !',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                this.dialog = true
                return false
                }
                this.loading = true
                this.loading_loader = true
                this.display = 'block'
                this.SearchData()
                this.SearchDataSum()
                this.getSumaja()
                this.getSumbrand()
            },

        async SearchData(){
            this.menu_headerss = []
            this.$store.dispatch('setOverlay', true)
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 8).replace(/-/g,"") : ''
            // var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 8).replace(/-/g,"") : ''
            var respData = await filter.fetchforecast (`?entity_id=${this.entiti ? this.entiti : ''}&office_id=${this.office ? this.office : ""}&region=${this.region ? this.region : ""}&period_start=${start_date ? start_date : ""}`, null, false, false, false)
            if (respData.status === 200) {
                //console.log(respData)
                this.menu_headerss = respData.data.data
                this.$store.dispatch('setOverlay', false)

            } else{
               this.$store.dispatch('setOverlay', false)
            }
        },

        async SearchDataSum(){
            this.menu_headers = []
            this.$store.dispatch('setOverlay', true)
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 8).replace(/-/g,"") : ''
            // var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 8).replace(/-/g,"") : ''
            var respData = await filter.fetchforecastdua (`?entity_id=${this.entiti ? this.entiti : ''}&office_id=${this.office ? this.office : ""}&region=${this.region ? this.region : ""}&period_start=${start_date ? start_date : ""}`, null, false, false, false)
            if (respData.status === 200) {
               // console.log(respData)
                this.menu_headers = respData.data.data
                this.$store.dispatch('setOverlay', false)

            } else{
               this.$store.dispatch('setOverlay', false)
            }
        },
    },
    
}
</script>

<style>

</style>